<template>
  <div class="user-home">
    <float-nav></float-nav>
    <div class="container">
      <div class="menu-group">
        <div
          class="menu-box"
          v-for="(item, index) in menuList"
          :key="index"
          @click="goPage(item)"
          :class="{ active: menuId === item.id }"
        >
          <div class="menu-title">
            <img class="img" @dragstart.prevent :src="item.imgUrl" />
            <img class="light-img" @dragstart.prevent :src="item.imgLight" />
            <span>{{ item.title }}</span>
          </div>
          <div
            v-for="(items, indexs) in item.children"
            :key="indexs"
            class="submenu-title"
            @click.stop="goPage(items)"
            :class="{ subactive: submenuId === items.id }"
          >
            {{ items.title }}
            <div class="noPay" v-if="items.num">
							{{items.num>99?'99+':items.num}}
						</div> 
          </div>
        </div>
        <!-- <div class="loginout" @click="loginOut">
          <img
            src="../../assets/img/user/loginout.png"
            width="100%"
            height="100%"
            alt=""
          />
          <span class="loginout-title">退出登录</span>
        </div> -->
      </div>
      <div class="container-content">
        <router-view></router-view>
      </div>
    </div>
    <agreement-modal
      ref="agreementModal"
      :title="'用户注册协议'"
      :text="false"
    ></agreement-modal>
    <privacy-modal ref="privacyModal"></privacy-modal>
    <disclaimer-modal ref="disclaimerModal"></disclaimer-modal>
  </div>
</template>

<script>
import FloatNav from "@/components/FloatNav";
import AgreementModal from "@/components/agreementModal";
import privacyModal from "@/components/privacyModal";
import DisclaimerModal from "@/components/disclaimerModal";
export default {
  components: {
    FloatNav,
    AgreementModal,
    privacyModal,
    DisclaimerModal
  },
  data() {
    return {
      path: "", // 当前路由路径
      menuId: 0,
      submenuId: 0,
      menuList: [
        {
          id: 0,
          imgUrl: require("../../assets/img/user/home.png"),
          imgLight: require("../../assets/img/user/home-light.png"),
          title: "首页",
          route: "/user/user-home",
          children: []
        },
        {
          id: 1,
          imgUrl: require("../../assets/img/user/collect.png"),
          imgLight: require("../../assets/img/user/collect-light.png"),
          title: "我的收藏",
          route: "/user/my-collect",
          children: []
        },
        {
          id: 2,
          imgUrl: require("../../assets/img/user/dingyue2.png"),
          imgLight: require("../../assets/img/user/dingyue-light.png"),
          title: "关注客户",
          route: "/user/my-collect/subscription",
          children: []
        },
        {
          id: 3,
          imgUrl: require("../../assets/img/user/kehu.png"),
          imgLight: require("../../assets/img/user/kehu-light.png"),
          title: "我的客户",
          route: "/user/my-collect/customer",
          children: []
        },
        {
          id: 4,
          imgUrl: require("../../assets/img/user/order.png"),
          imgLight: require("../../assets/img/user/order-light.png"),
          title: "我的订单",
          route: "/user/my-order",
          children: [
            {
              id: 41,
              title: "数据超市订单",
              route: "/user/my-order/data-market-order"
            },
            {
              id: 42,
              title: "专题报告订单",
              route: "/user/my-order/subdivide-order"
            },
            {
              id: 43,
              title: "市场简报订单",
              route: "/user/my-order/report-order"
            },
            {
              id: 44,
              title: "咨询/定制服务订单",
              route: "/user/my-order/custom-order"
            },
            {
              id: 45,
              title: "会员服务订单",
              route: "/user/my-order/vip-order"
            },
            
            {
              id: 46,
              title: "行业动态订单",
              route: "/user/my-order/industry-order"
            },
            // {
            //   id: 47,
            //   title: "数据收费订单",
            //   route: "/user/my-order/data-order"
            // }
          ]
        },
        {
          id: 5,
          imgUrl: require("../../assets/img/user/invoice.png"),
          imgLight: require("../../assets/img/user/invoice-light.png"),
          title: "我的发票",
          route: "/user/my-invoice",
          children: [
            {
              id: 51,
              title: "待开发票",
              route: "/user/my-invoice/be-invoice"
            },
            {
              id: 52,
              title: "已开发票",
              route: "/user/my-invoice/invoiced"
            }
          ]
        },
        {
          id: 6,
          imgUrl: require("../../assets/img/user/buy.png"),
          imgLight: require("../../assets/img/user/buy-light.png"),
          title: "我的已购",
          route: "/user/my-buyed",
          children: [
            {
              id: 61,
              title: "已购服务",
              route: "/user/my-buyed/buyed-service"
            },
            {
              id: 62,
              title: "下载记录",
              route: "/user/my-buyed/download-record"
            }
          ]
        },
        {
          id: 7,
          imgUrl: require("../../assets/img/user/user.png"),
          imgLight: require("../../assets/img/user/user-light.png"),
          title: "个人中心",
          route: "/user/my-center",
          children: [
            {
              id: 71,
              title: "账号管理",
              route: "/user/my-center/account-manage"
            },
            {
              id: 72,
              title: "修改资料",
              route: "/user/my-center/modify-data"
            },
            {
              id: 73,
              title: "修改绑定手机号",
              route: "/user/my-center/modify-phone"
            },
            {
              id: 74,
              title: "注册协议"
            },
            {
              id: 75,
              title: "隐私政策"
            },
            {
              id: 76,
              title: "免责声明"
            }
          ]
        }
      ],
      subMenuList: [
        {
          id: 0,
          imgUrl: require("../../assets/img/user/home.png"),
          imgLight: require("../../assets/img/user/home-light.png"),
          title: "首页",
          route: "/user/user-home",
          children: []
        },
        {
          id: 1,
          imgUrl: require("../../assets/img/user/collect.png"),
          imgLight: require("../../assets/img/user/collect-light.png"),
          title: "我的收藏",
          route: "/user/my-collect",
          children: []
        },
        {
          id: 2,
          imgUrl: require("../../assets/img/user/dingyue2.png"),
          imgLight: require("../../assets/img/user/dingyue-light.png"),
          title: "关注客户",
          route: "/user/my-collect/subscription",
          children: []
        },
        {
          id: 3,
          imgUrl: require("../../assets/img/user/kehu.png"),
          imgLight: require("../../assets/img/user/kehu-light.png"),
          title: "我的客户",
          route: "/user/my-collect/customer",
          children: []
        },
        {
          id: 4,
          imgUrl: require("../../assets/img/user/order.png"),
          imgLight: require("../../assets/img/user/order-light.png"),
          title: "我的订单",
          route: "/user/my-order",
          children: [
            {
              id: 41,
              title: "数据超市订单",
              route: "/user/my-order/data-market-order"
            },
            {
              id: 42,
              title: "专题报告订单",
              route: "/user/my-order/subdivide-order"
            },
            {
              id: 43,
              title: "市场简报订单",
              route: "/user/my-order/report-order"
            },
            {
              id: 44,
              title: "咨询/定制服务订单",
              route: "/user/my-order/custom-order"
            },
            {
              id: 45,
              title: "会员服务订单",
              route: "/user/my-order/vip-order"
            },
            {
              id: 46,
              title: "行业动态订单",
              route: "/user/my-order/industry-order"
            },
            // {
            //   id: 47,
            //   title: "数据收费订单",
            //   route: "/user/my-order/data-order"
            // }
          ]
        },
        {
          id: 5,
          imgUrl: require("../../assets/img/user/invoice.png"),
          imgLight: require("../../assets/img/user/invoice-light.png"),
          title: "我的发票",
          route: "/user/my-invoice",
          children: [
            {
              id: 51,
              title: "待开发票",
              route: "/user/my-invoice/be-invoice"
            },
            {
              id: 52,
              title: "已开发票",
              route: "/user/my-invoice/invoiced"
            }
          ]
        },
        {
          id: 6,
          imgUrl: require("../../assets/img/user/buy.png"),
          imgLight: require("../../assets/img/user/buy-light.png"),
          title: "我的已购",
          route: "/user/my-buyed",
          children: [
            {
              id: 61,
              title: "已购服务",
              route: "/user/my-buyed/buyed-service"
            },
            {
              id: 62,
              title: "下载记录",
              route: "/user/my-buyed/download-record"
            }
          ]
        },
        {
          id: 7,
          imgUrl: require("../../assets/img/user/user.png"),
          imgLight: require("../../assets/img/user/user-light.png"),
          title: "个人中心",
          route: "/user/my-center",
          children: [
            {
              id: 72,
              title: "修改资料",
              route: "/user/my-center/modify-data"
            },
            {
              id: 73,
              title: "修改绑定手机号",
              route: "/user/my-center/modify-phone"
            },
            {
              id: 74,
              title: "注册协议"
            },
            {
              id: 75,
              title: "隐私政策"
            },
            {
              id: 76,
              title: "免责声明"
            }
          ]
        }
      ],
      normalMenuList: [
        {
          id: 0,
          imgUrl: require("../../assets/img/user/home.png"),
          imgLight: require("../../assets/img/user/home-light.png"),
          title: "首页",
          route: "/user/user-home",
          children: []
        },
        {
          id: 1,
          imgUrl: require("../../assets/img/user/collect.png"),
          imgLight: require("../../assets/img/user/collect-light.png"),
          title: "我的收藏",
          route: "/user/my-collect",
          children: []
        },
        {
          id: 4,
          imgUrl: require("../../assets/img/user/order.png"),
          imgLight: require("../../assets/img/user/order-light.png"),
          title: "我的订单",
          route: "/user/my-order",
          children: [
            {
              id: 41,
              title: "数据超市订单",
              route: "/user/my-order/data-market-order"
            },
            {
              id: 42,
              title: "专题报告订单",
              route: "/user/my-order/subdivide-order"
            },
            {
              id: 43,
              title: "市场简报订单",
              route: "/user/my-order/report-order"
            },
            {
              id: 44,
              title: "咨询/定制服务订单",
              route: "/user/my-order/custom-order"
            },
            {
              id: 45,
              title: "会员服务订单",
              route: "/user/my-order/vip-order"
            },
            {
              id: 46,
              title: "行业动态订单",
              route: "/user/my-order/industry-order"
            },
            // {
            //   id: 47,
            //   title: "数据收费订单",
            //   route: "/user/my-order/data-order"
            // }
          ]
        },
        {
          id: 5,
          imgUrl: require("../../assets/img/user/invoice.png"),
          imgLight: require("../../assets/img/user/invoice-light.png"),
          title: "我的发票",
          route: "/user/my-invoice",
          children: [
            {
              id: 51,
              title: "待开发票",
              route: "/user/my-invoice/be-invoice"
            },
            {
              id: 52,
              title: "已开发票",
              route: "/user/my-invoice/invoiced"
            }
          ]
        },
        {
          id: 6,
          imgUrl: require("../../assets/img/user/buy.png"),
          imgLight: require("../../assets/img/user/buy-light.png"),
          title: "我的已购",
          route: "/user/my-buyed",
          children: [
            {
              id: 61,
              title: "已购服务",
              route: "/user/my-buyed/buyed-service"
            },
            {
              id: 62,
              title: "下载记录",
              route: "/user/my-buyed/download-record"
            }
          ]
        },
        {
          id: 7,
          imgUrl: require("../../assets/img/user/user.png"),
          imgLight: require("../../assets/img/user/user-light.png"),
          title: "个人中心",
          route: "/user/my-center",
          children: [
            {
              id: 72,
              title: "修改资料",
              route: "/user/my-center/modify-data"
            },
            {
              id: 73,
              title: "修改绑定手机号",
              route: "/user/my-center/modify-phone"
            },
            {
              id: 74,
              title: "注册协议"
            },
            {
              id: 75,
              title: "隐私政策"
            },
            {
              id: 76,
              title: "免责声明"
            }
          ]
        }
      ]
    };
  },
  computed: {},
  watch: {
    $route() {
      let path = this.$route.path;
      this.path = path;
      let { menuList } = this;
      let key = -1;
      let keys = -1;
      menuList.forEach(item => {
        if (path.includes(item.route)) {
          key = item.id;
        }
        item.children.forEach(items => {
          if (path == items.route) {
            keys = items.id;
          }
        });
      });
      this.menuId = key;
      this.submenuId = keys;
    }
  },
  mounted() {
    let path = this.$route.path;
    this.path = path;
    let { menuList } = this;
    let key = -1;
    let keys = -1;
    menuList.forEach(item => {
      if (path.includes(item.route)) {
        key = item.id;
      }
      item.children.forEach(items => {
        if (path == items.route) {
          keys = items.id;
        }
      });
    });
    this.menuId = key;
    this.submenuId = keys;

    // 角色鉴权，个人登录没有账号管理模块
    this.getUserInfo();
  },
  methods: {
    // 获取我的订单未支付数
		getNoPayCount() {
			this.$api.personalCenter.getNoOrderCount().then(res => {
        this.menuList.map(item=>{
          if(item.title=='我的订单'){
            item.children[0].num=res.data.supermarketsCount
            item.children[1].num=res.data.subdivideOrderCount
            item.children[2].num=res.data.dataReportOrderCount
            item.children[3].num=res.data.OrderCustomizedConsultingCount
            item.children[4].num=res.data.memberOrderCount
            item.children[5].num=res.data.dataIndustryOrderCount
            item.children[0].route=item.children[0].route+`?noPay=${item.children[0].num}`
            item.children[1].route=item.children[1].route+`?noPay=${item.children[1].num}`
            item.children[2].route=item.children[2].route+`?noPay=${item.children[2].num}`
            item.children[3].route=item.children[3].route+`?noPay=${item.children[3].num}`
            item.children[4].route=item.children[4].route+`?noPay=${item.children[4].num}`
            item.children[5].route=item.children[5].route+`?noPay=${item.children[5].num}`
          }
          this.$forceUpdate()
        })
			});
		},
    // 获取用户登录信息
    getUserInfo() {
      this.$api.personalCenter.getInfo().then(res => {
        if (
          res.data.consumerType == "60461002" &&
          res.data.memberName == "SVIP" &&
          !res.data.pid
        ) {
          // this.menuList = this.menuList;
        } else {
          if (res.data.isMember == 0) {
            this.menuList = this.normalMenuList;
          } else {
            this.menuList = this.subMenuList;
          }
        }
        this.getNoPayCount()
      });
    },
    // 路由点击跳转
    goPage(item) {
      console.log(item);
      if (item.title == "注册协议") {
        this.$refs.agreementModal.visible = true;
      } else if (item.title == "隐私政策") {
        this.$refs.privacyModal.visible = true;
      } else if (item.title == "免责声明") {
        this.$refs.disclaimerModal.visible = true;
      } else {
        if (item.children) {
          if (item.children.length == 0) {
            this.menuId = item.id;
            this.submenuId = null;
            if (this.$route.path != item.route) {
              this.$router.push(item.route);
            }
          } else {
            this.menuId = item.id;
            this.submenuId = item.children[0].id;
            if (this.$route.path != item.children[0].route) {
              this.$router.push(item.children[0].route);
            }
          }
        } else {
          if (item.id > 10 && item.id < 20) {
            this.menuId = 1;
          } else if (item.id > 20 && item.id < 30) {
            this.menuId = 2;
          } else if (item.id > 30 && item.id < 40) {
            this.menuId = 3;
          } else if (item.id > 40 && item.id < 50) {
            this.menuId = 4;
          } else if (item.id > 50 && item.id < 60) {
            this.menuId = 5;
          } else if (item.id > 60 && item.id < 70) {
            this.menuId = 6;
          } else if (item.id > 70 && item.id < 80) {
            this.menuId = 7;
          }
          this.submenuId = item.id;
          if (this.$route.path != item.route) {
            this.$router.push(item.route);
          }
        }
      }
    },
    // 退出登录
    loginOut() {
      // 发请求
      this.$message.success("已退出登录");
      localStorage.clear();
      this.$router.push("/login");
    }
  }
};
</script>
<style lang="less" scope>
@import "./index.less";
</style>
